@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Quicksand', sans-serif;
}

body{
  background: black;
}

h1, h4, p, a {
  color: #fff;
  text-decoration: none;
}

ul{
  list-style-type: none;
}

.btn{
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: rgb(248,217,15);
  color: #222;
  font-weight: 600;
  cursor: pointer;
}

.btn-light{
  background: transparent;
  color: #fff;
}

.btn:hover{
  background: rgba(255,255,255,0.2);
  color: #fff;
  transition: 0.3s;
}

.filter-ctn{
  text-align: center;
  padding-top: 5rem;
}

.filter-heading{
  display: inline-block;
  margin: auto;
}

.filter-btn{
  display: inline-block;
  width: auto;
  height: auto;
  flex: auto;
  margin: 2rem 1rem;
}

h2{
  color: #fff;
}

.home-btn{
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: rgba(25,25,25,.8);
  color: #eee;
  font-weight: 600;
  cursor: pointer;
}

.home-btn-ctn{
display: flex;
align-items: center;
justify-content: center;
padding-top: 20px;
}

.home-card{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.footer{
    width: 100%;
    padding: 6rem 0;
    background-color: rgba(19,19,19,0.8);
}

.footer-container{
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 40px;
}

.left{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.location{
    display: flex;
    margin-bottom: 0.8rem;
}

.location p{
    line-height: 30px;
}

.right{
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem
}

.social{
    margin-top: 1rem;
}

h4{
    font-size: 1.2rem;
    padding-bottom: 0.7rem;
}

p{
    font-size: 1.2rem;
}

@media  screen and (max-width:640px) {
    .footer-container{
        grid-template-columns: 1fr;
    }
}
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    position: fixed;
    width: 100%;
    height: 90px;
    z-index: 10;
}

.header-bg{
    background-color: rgba(0,0,0,0.9);
    transition: 0.5s;
}

.nav-menu{
    display: flex;
}

.nav-menu li{
    padding: 0 1rem;
}

.nav-menu li a {
    font-size: 1.2rem;
    font-weight: 500;
}

.hamburger{
    display: none;
}

@media screen and (max-width:1040px){
    .nav-menu{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        background: rgba(0,0,0,0.9);
        position: absolute;
        top: 0;
        left: -100%;
        z-index: -3;
        transition: 0.3s;
    }
    .nav-menu.active{
        left: 0;
    }

    .nav-menu li{
        padding: 1rem 0;
    }
    .nav-menu li a{
        font-size: 2rem;
    }
    .hamburger{
        display: inline;
        display: initial;
    }
}
.hero-img{
    width: 100%;
    background: rgba(0,0,0,0.8);
    height: 60vh;
    position: relative;
}

.hero-img::before{
    content: "";
    background-image: url(/static/media/hero.b9e57e92.jpg);
    background-size: cover;
    background-position: center top;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left:0;
    z-index: -1;
}

.heading{
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero-img h1{
    font-size: 2.4rem;
}

.hero-img p{
    font-size: 1.4rem;
    text-align: center;
}

@media screen and (max-width: 640px){
    .hero-img h1{
        font-size: 2rem;
    }
}
.project-heading{
    text-align: center;
    padding: 4rem 0 2rem 0;
}

.project-container{
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 40px;
    padding-bottom: 5rem;
}

.project-card{
    background: #1a1919;
    padding: 1.2rem 1rem;
}


.project-card img{
    width: 100%;
    transition: all .3s ease-in-out;
}

.project-title{
    color: #fff;
    padding: 1rem 0;
}

.pro-details{
    padding-bottom: 1rem;
    font-size: 1.1rem;
    text-align: justify;
}

.pro-btns{
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
}

.pro-btns .btn{
    padding: 0.5rem 1rem;
}



@media screen and (maxwidth:740px) {
    .project-container{
        max-width: 90%;
        margin: auto;
        grid-template-columns: 1fr;
    }
}
.about{
    width:100%;
    margin: 3rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
}

.about .left{
    text-align: center;
    margin: auto;
    padding: 1rem;
    max-width: 350px;
}

.about .left p{
    margin: 1.2rem 0;
}

.about .right{
    max-width: 700px;
}
.right .img-ctn{
    display:grid;
    grid-template-columns: repeat(12,1fr);
    position: relative;
    text-align: center;
    align-items: center;
}

.right .img{
    max-width: 90%;
    border: 1px solid #333;
}

.right .top{
    grid-column: 1 / span 8;
    grid-row: 1;
    padding-top: 50%;
    z-index: 6;
}
.right .bottom{
    grid-column: 4 / -1;
    grid-row: 1;
    z-index: 5;
}
label{
    color:#fff;
    margin-bottom: 0.5rem;
}

form{
    display: flex;
    flex-direction: column;
    padding: 4rem 1rem;
    margin: auto;
    max-width: 600px;
}

form input, form textarea{
    margin-bottom: 1rem;
    padding: 10px 18px;
    font-size: 1.2rem;
    background-color: rgba(255,255,255,0.1);
    border-color: rgba(255,255,255,0.1);
    color: #f4f4f4;
}
