@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Quicksand', sans-serif;
}

body{
  background: black;
}

h1, h4, p, a {
  color: #fff;
  text-decoration: none;
}

ul{
  list-style-type: none;
}

.btn{
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: rgb(248,217,15);
  color: #222;
  font-weight: 600;
  cursor: pointer;
}

.btn-light{
  background: transparent;
  color: #fff;
}

.btn:hover{
  background: rgba(255,255,255,0.2);
  color: #fff;
  transition: 0.3s;
}

.filter-ctn{
  text-align: center;
  padding-top: 5rem;
}

.filter-heading{
  display: inline-block;
  margin: auto;
}

.filter-btn{
  display: inline-block;
  width: auto;
  height: auto;
  flex: auto;
  margin: 2rem 1rem;
}

h2{
  color: #fff;
}

.home-btn{
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: rgba(25,25,25,.8);
  color: #eee;
  font-weight: 600;
  cursor: pointer;
}

.home-btn-ctn{
display: flex;
align-items: center;
justify-content: center;
padding-top: 20px;
}

.home-card{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}